<template>
  <sf-card title="TOP/BOTTOM USER">
    <div class="top-bottom-user">
      <el-row class="header">
        <el-col :span="4">用户名</el-col>
        <el-col :span="12">过去7天平均工作有效工作占比</el-col>
        <el-col :span="4" style="text-align: right">过去7天有效工时</el-col>
        <el-col :span="4" style="text-align: right">总工时</el-col>
      </el-row>
      <p class="title">TOP USER</p>
      <el-row
        v-for="item in topUsers"
        :key="item"
        class="user"
      >
        <el-col :span="4">
          {{item.name}}
        </el-col>
        <el-col :span="12" class="percentage">
          <div class="percentage-bar"><div class="filled" :style="{ width: `${item.percentage * 100}%`}"></div></div>
          <span class="unit">{{item.percentage * 100}}%</span>
        </el-col>
        <el-col :span="4" style="text-align: right">
          {{item.effectiveHours}}
          <span class="unit">小时</span>
        </el-col>
        <el-col :span="4" style="text-align: right">
          {{item.totalHours}}
          <span class="unit">小时</span>
        </el-col>
      </el-row>
      <p class="title bottom">BOTTOM USER</p>
      <el-row
        v-for="item in bottomUsers"
        :key="item"
        class="user bottom"
      >
        <el-col :span="4">
          {{item.name}}
        </el-col>
        <el-col :span="12" class="percentage">
          <div class="percentage-bar"><div class="filled" :style="{ width: `${item.percentage * 100}%`}"></div></div>
          <span class="unit">{{item.percentage * 100}}%</span>
        </el-col>
        <el-col :span="4" style="text-align: right">
          {{item.effectiveHours}}
          <span class="unit">小时</span>
        </el-col>
        <el-col :span="4" style="text-align: right">
          {{item.totalHours}}
          <span class="unit">小时</span>
        </el-col>
      </el-row>
      <el-row class="user">
        <el-col :span="12" :offset="4">
          <div class="average">
            85%
            <span class="unit">（公司平均有效工作小时占比）</span>
            <div class="average-line" style="left: calc(50%)"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </sf-card>
</template>

<script>
export default {
  data() {
    return {
      topUsers: [
        {
          name: 'Anwar.H',
          percentage: 0.98,
          effectiveHours: 30,
          totalHours: 40,
        },
        {
          name: 'Shelley',
          percentage: 0.9,
          effectiveHours: 27,
          totalHours: 32,
        },
        {
          name: 'Leo',
          percentage: 0.89,
          effectiveHours: 25,
          totalHours: 28,
        },
        {
          name: 'Leo',
          percentage: 0.86,
          effectiveHours: 22,
          totalHours: 26,
        },
        {
          name: 'Leo',
          percentage: 0.85,
          effectiveHours: 20,
          totalHours: 22,
        },
      ],
      bottomUsers: [
        {
          name: 'Anwar.H',
          percentage: 0.98,
          effectiveHours: 30,
          totalHours: 40,
        },
        {
          name: 'Shelley',
          percentage: 0.9,
          effectiveHours: 27,
          totalHours: 32,
        },
        {
          name: 'Leo',
          percentage: 0.89,
          effectiveHours: 25,
          totalHours: 28,
        },
        {
          name: 'Leo',
          percentage: 0.86,
          effectiveHours: 22,
          totalHours: 26,
        },
        {
          name: 'Leo',
          percentage: 0.85,
          effectiveHours: 20,
          totalHours: 22,
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.top-bottom-user {
  position: relative;
}
.header {
  @include text-regular($color-text-regular);
}
.title {
  margin-top: $padding;
  @include text-regular($color-deep-green);
  font-weight: 500;
  &.bottom {
    color: $color-deep-red;
  }
}
.user {
  position: static;
  padding: $padding-s 0;
  @include text-regular($color-text-primary);
  .unit {
    @include text-regular($color-text-secondary);
  }
  &:last-child {
    padding-bottom: $padding-s;
  }
  .percentage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .percentage-bar {
      flex: 1;
      height: 12px;
      border-radius: 12px;
      background-color: #EDEDED;
      overflow: hidden;
      .filled {
        height: 12px;
        border-radius: 12px;
        background-color: $color-deep-green;
      }
      & + .unit {
        width: 45px;
        text-align: right;
      }
    }
  }
  &.bottom {
    .percentage {
      .percentage-bar {
        .filled {
          background-color: $color-deep-red;
        }
      }
    }
  }
}
.average {
  text-align: right;
  .average-line {
    position: absolute;
    top: 42px;
    height: calc(100% - 42px - 42px);
    width: 1px;
    background-color: #c1c1c1;
    z-index: 1;
  }
}
</style>