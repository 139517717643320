<template>
  <sf-card title="过去7天有效工时环比增幅前5与降幅前5">
    <div class="chart-container">
      <div ref="chart" style="height: 468px"></div>
    </div>
  </sf-card>
</template>
<script>
import * as echarts from 'echarts';

let barChart = null;


export default {
  data(){
    return {
      users:[],
      originData:{
            increasing_top_n:[
                {"name":"周芷若", "work_period":168, "rate":0.15},
                {"name":"赵敏", "work_period":142, "rate":0.11},
                {"name":"张无忌", "work_period":133, "rate":0.08},
            ],
            decreasing_top_n:[
                {"name":"乔峰", "work_period":158, "rate":0.14},
                {"name":"虚竹", "work_period":132, "rate":0.12},
                {"name":"段誉", "work_period":123, "rate":0.07},
            ]
        },
      datas: [],
    }
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart(){
      const that = this;
      barChart = echarts.init(this.$refs.chart);
      const inscArray = this.originData.increasing_top_n.map(item=> item['name'])

      const descArray = this.originData.decreasing_top_n.map(item=> item['name'])

      inscArray.push(...descArray)

      const option = {
          grid:{
            height: 360,
          },
          splitLine: {
              show:false,
          },
          xAxis: {
              type: 'category',
              data: inscArray,
              minorTick: {
                  show: false
              },
              minorSplitLine: {
                  show: false
              },
              axisTick:{
                show: false,  
              },
              axisLine:{
                  show:true,
              },
              axisLabel:{
                rotate: 30  ,
                position: 'bottom',
                verticalAlign: 'top',
                align: 'center',
              },
          },
          yAxis: {
              type: 'value',
              minorTick: {
                  show: false
              },
              minorSplitLine: {
                  show: false
              },
              axisLine:{
                  show:true,
                  symbol: ['arrow','arrow']
              },
          },
          series: [{
              data: (function (){
                var desc = that.originData.decreasing_top_n.map(item => {
                  item['rate'] = item['rate']*(-1);
                  return item;
                })
                console.log(desc);
                var insc = that.originData.increasing_top_n
                insc.push(...desc)
                return insc.map(item => item['rate']);
            })(),
              label:{
                show: true,
                position: 'top',
                formatter: (params) => {
                  return Math.round(Math.abs(params['data']*100));
                },
                valueAnimation: true
              },
              type: 'bar',
              barWidth: '30%',
          }]
      };
      barChart.setOption(option);
    },
  },
}
</script>