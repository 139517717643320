<template>
  <sf-card title="个人工时分布">
    <div ref="chart" class="chart" style="height: 300px"></div>
  </sf-card>
</template>

<script>
import * as echarts from 'echarts';
import moment from 'moment'

let barChart = null;

var dataCount = 96;
var startTime = new Date(2021, 5, 14, 1, 0, 0, 0).getTime();
var duration = 1000*60*15;
var categories = ['2020-5-14', '2020-5-13', '2020-5-12','2020-5-11','2020-5-10'];
var types = [
    {name: 'active', color: '#19C2B5'},
    {name: 'inactive', color: '#E16133'},
    {name: 'offline', color: '#BBBBBB'}
];

function renderItem(params, api) {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var height = api.size([0, 1])[1] * 0.4;

    var rectShape = echarts.graphic.clipRectByRect({
        x: start[0]+0.5,
        y: start[1] - height / 2,
        width: end[0] - start[0] - 1,
        height: height
    }, {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height
    });

    return rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: api.style()
    };
}

// Generate mock data

export default {
  mounted() {
    this.renderChart();
  },
  beforeUpdate() {
    this.mockData();
  },
  updated() {
    this.renderChart();
  },
  data() {
    return {
      workhours: [],
    }
  },
  methods: {
    mockData(){
      var that = this;
      var typeItem = null;
        categories.forEach(function (category, index) {
          console.log(`${index} -> ${category}`);
          var baseTime = startTime;
          for (var i = 0; i < dataCount; i++) {
            typeItem = types[Math.round(Math.random() * (types.length - 1))];
              that.workhours.push({
                  name: typeItem.name,
                  value: [
                    index,
                      baseTime,
                      baseTime += duration,
                      duration
                  ],
                  itemStyle: {
                    normal: {
                      color: typeItem.color
                      }
                  }
              });
          }
      console.log(typeItem);
      });
    },
    renderChart() {
      this.mockData();
      barChart = echarts.init(this.$refs.chart);
      moment.locale("zh-cn")
      const option = {
        tooltip: {
            formatter: function (params) {
                var now = new Date(params.value[1])
                return `${params.marker} ${moment(now).format('HH:mm')} ${params.name}`;
            }
        },
        grid: {
            height: 220
        },
        xAxis: {
            type: 'time',
            min: startTime,
            interval: duration*4,
            maxInterval: duration*4,
            boundaryGap: ['20%', '0%'],
            axisLine: {
                show: false,
            },
            axisTick: {
                    show:false,
            },
            axisLabel: {

                color: '#CCCCCC',
                formatter: function () {
                    return'{HH}:{mm}';
                }
            }
        },
        yAxis: {
            data: categories,
            boundaryGap: true,
            axisLabel:{
                margin: 10,
            },
            axisTick: {
                    show:false,
                },
            axisLine: {
                symbolOffset: 20,
                show: true,
                axisTick: {
                    show:false
                },
                lineStyle: {
                    color: '#dddddd',
                }
            },
        },
        series: [{
            type: 'custom',
            renderItem: renderItem,
            itemStyle: {
                opacity: 0.9
            },
            encode: {
                x: [1, 2],
                y: 0
            },
            data: this.workhours
        }]
      }
      barChart.setOption(option);
    }
  },
}
</script>
