<template>
  <el-row :gutter="20" class="row">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <TopAndBottomUser />
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <IncreaseAndDecrease />
    </el-col>
    <el-col :span="24">
      <HoursDistribution />
    </el-col>
  </el-row>
</template>

<script>
import HoursDistribution from './components/hoursDistribution';
import IncreaseAndDecrease from './components/increaseAndDecrease';
import TopAndBottomUser from './components/topAndBottomUser';

export default {
  components: {
    HoursDistribution,
    IncreaseAndDecrease,
    TopAndBottomUser,
  }
}
</script>

<style lang="scss" scoped>
.row {
  margin-top: -$padding-s;
  .el-col {
    margin-top: $padding;
  }
}
.card {
  height: 100%;
}
</style>